import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  inject,
  input,
  OnInit,
  output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCartItems } from '../../store/cart.reducer';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';
import { CartItem } from '../../models';
import { BuyerRoutes } from '@chassis/buyer/routing';
import { tuiPure } from '@taiga-ui/cdk';

interface Totals {
  total: number;
  [key: number]: {
    coreTotal: number;
    costTotal: number;
  };
}

@Component({
  selector: 'cart-shared-summary',
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent implements OnInit {
  isCheckout = input.required<boolean>();

  private componentWidthSubject = new BehaviorSubject<number>(0);
  private store = inject(Store);

  componentWidth$: Observable<number> = this.componentWidthSubject
    .asObservable()
    .pipe(
      map((v) => {
        if (v > 700) {
          return v;
        } else if (v > 450) {
          return 50;
        } else if (v > 250) {
          return 15;
        } else {
          return 10;
        }
      }),
    );
  cartItems$ = this.store.select(selectCartItems);

  public checkoutRoute = BuyerRoutes.checkout;

  data$: Observable<{ totals: Totals; cartItems: CartItem[] }> = this.store
    .select(selectCartItems)
    .pipe(
      filter((cartItems) => !!cartItems?.length),
      map((cartItems) => {
        return {
          totals: this.calculateOrderTotal(cartItems),
          cartItems: cartItems.map((c) => {
            return { ...c, current_core_charge: 2.0 };
          }),
        };
      }),
    );

  cartSubmit = output<boolean>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.updateComponentWidth();
  }

  itemTotal(cartItem: CartItem) {
    return (
      Number(cartItem.current_core_charge || 0) + Number(cartItem.cost_price)
    );
  }

  calculateOrderTotal(cartItems: CartItem[]) {
    return cartItems.reduce(
      (total, cartItem) => {
        total[cartItem.id] = {
          coreTotal:
            cartItem.quantity * Number(cartItem.current_core_charge || 0),
          costTotal: cartItem.quantity * Number(cartItem.cost_price || 0),
        };

        total.total += this.itemTotal(cartItem) * cartItem.quantity;

        return total;
      },
      { total: 0 } as Totals,
    );
  }
  @tuiPure
  getOrderTotal(cartItems: CartItem[]) {
    return cartItems.reduce((sum, cartItem) => {
      sum += this.itemTotal(cartItem) * cartItem.quantity;

      return sum;
    }, 0);
  }

  trackCartItem(cartItem: CartItem) {
    return cartItem.id;
  }

  @HostListener('window:resize')
  onResize() {
    this.updateComponentWidth();
  }

  private updateComponentWidth() {
    const width = this.elementRef.nativeElement.clientWidth;
    this.componentWidthSubject.next(width);
  }
}
