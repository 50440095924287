<h3>Order Summary</h3>
<ng-container
  *ngrxLet="{
    cartItems: cartItems$,
    data: data$,
  } as vm"
>
  @for (cartItem of vm.cartItems; track trackCartItem(cartItem)) {
    <div class="product-row">
      <div class="product-info">
        <img
          [ngSrc]="cartItem.product_image_url"
          width="75"
          height="75"
          default="/assets/test.jpg"
          [alt]="cartItem.product_name"
        />
        <div class="product-props" *ngrxLet="componentWidth$; let width">
          <p class="product-name">
            {{ cartItem.product_name | ellipsis: width }}
          </p>
          @if (cartItem.hollander_number) {
            <p>{{ cartItem.hollander_number }}</p>
          }
          @if (cartItem.external_inventory_id) {
            <p>R{{ cartItem.external_inventory_id }}</p>
          }
          @if (cartItem.current_core_charge) {
            <h4>Core Charge</h4>
          }
        </div>
      </div>
      <div class="revenue">
        <p>{{ vm.data.totals[cartItem.id].costTotal | currency: 'USD' }}</p>
        @if (cartItem.current_core_charge) {
          <p class="core-charge">
            {{ vm.data.totals[cartItem.id].coreTotal | currency: 'USD' }}
          </p>
        }
      </div>
    </div>
  }
  <div class="flex-justify row">
    <h4>Shipping</h4>
    <p>Free</p>
  </div>
  <div class="flex-justify row">
    <h4>Tax</h4>
    <p>{{ 0.0 | currency: 'USD' }}</p>
  </div>

  <div class="flex-justify row">
    <h3>What You Owe</h3>
    <h3>{{ getOrderTotal(vm.cartItems) | currency: 'USD' }}</h3>
  </div>
  @if (isCheckout()) {
    <button tuiButton class="place-order-btn" (click)="cartSubmit.emit(true)">
      Place Order
    </button>
  } @else {
    <a
      [class]="!vm.cartItems?.length ? 'disabledTag' : ''"
      tuiButton
      size="m"
      type="button"
      [class.disabled]="!vm.cartItems?.length"
      [routerLink]="$any(checkoutRoute)"
      class="tui-space_right-3 tui-space_bottom-3 btn"
      data-testid="checkout-checkout-btn"
    >
      Checkout
    </a>
  }
</ng-container>
